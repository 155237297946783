
.rounded-border {
    border-radius: 40px !important;
}

.positions {
    font-size: 11px;
    margin-left: 5%;
    margin-bottom: 2%;
    color: #1d144f !important;
}

.positions-header {
    margin-left: 5%;
    margin-bottom: 3%;
    color: #2D2CE5 !important;
    font-size: 14px;
}

.unified-style {
   font-family: Graphik, "Times New Roman", serif !important;
   color: #2D2CE5 !important;
}

.graphik-font {
    font-family: Graphik, "Times New Roman", serif !important;
}

.card-title {
    font-size: 25px;
    margin-bottom: 5%;
    padding-top: 10px;
}

.card-header {
    margin-left: 5%;
}

.logo-header {
    margin-right: 5%;
    margin-left: 6%;
}

.card {
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    margin-top: 1%;
    margin-bottom: 1%;
}

.selector {
    margin-top: 15%;
    background: linear-gradient(90deg, #2D2CE5, #6BB1FF);
}

.select-dropdown {
    margin-top: 1%;
    margin-bottom: -8%;
    margin-left: 25%;
}

.network-font {
    color: white !important;
}

.address {
    font-size: 12px;
    margin-bottom: 1%;
    color: rgba(0, 0, 0, 0.6);
    margin-right: 5%;
}

.page-title {
    display: block;
    margin-top: 2%;
    margin-bottom: 1%;
    margin-left: 2%;
    color: #1d144f !important;
}

.position-name {
    position:relative;
    top:-8px;
    padding-left: 3%;
}

a {
    text-decoration: none;
    color: inherit;
}

.expand-button {
    margin-right: 3% !important;
    margin-bottom: 2% !important;
}

.error-msg {
    font-family: Graphik, "Times New Roman", serif !important;
    color: #e52c48 !important;
}
